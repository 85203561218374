import { N50 } from '@/styles/color';
import { css } from '@emotion/css';

interface Props {
  height?: number;
  margin?: number;
}

const Divider = ({ height = 1, margin = 0 }: Props) => {
  return (
    <div
      className={css`
        width: calc(100% - ${margin * 2}px);
        height: ${height}px;
        background-color: ${N50};
        margin: auto;
      `}
    />
  );
};

export default Divider;
