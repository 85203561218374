/**
 * @TODO for now we just stringify & parse, later we can handle this.
 * this is also a workaround for this issues:
 * - react-query [https://github.com/tannerlinsley/react-query/issues/3149]
 * - nextjs [https://github.com/vercel/next.js/discussions/11209]
 *
 * @function sanitize
 * @param json
 */
const sanitize = (json: any) => {
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (error) {
    return {};
  }
};

export default sanitize;
