/**
 * @function isArray
 * @param {any} val
 * @returns {boolean}
 */
const isArray = (val: any): boolean => {
  return val instanceof Array && Array.isArray(val);
};

export default isArray;
