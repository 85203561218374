import { N200 } from '@/styles/color';
import { css } from '@emotion/css';

export const productCardContainerCx = css`
  border-radius: 12px;
  border: 1px solid ${N200};
  position: relative;
  flex: 0 0 auto;
  width: 244px;
  border-bottom: 1px solid ${N200};
`;

export const productCardHeaderCx = css`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);
`;

export const productWishlistButtonCx = css`
  border: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  height: 32px;
  width: 32px;
`;

export const productImageContainerCx = css`
  width: 100%;
  height: 162px;
  position: relative;
  border-bottom: 1px solid ${N200};
`;

export const productBodyCx = css`
  padding: 12px;
`;

export const productTagContainerCx = css`
  display: flex;
  margin-top: 8px;
  column-gap: 4px;
`;

export const productFooterCx = css`
  display: flex;
  padding: 12px;
  column-gap: 12px;
  border-top: 1px solid ${N200};
`;

export const productFooterItemCx = css`
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex: 0 0 auto;

  & p {
    margin: 0;
  }
`;
