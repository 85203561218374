import type { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import { containerCx } from './styles';

interface Props {
  children: ReactNode;
  rounded?: boolean;
}

const Label = ({ children, rounded }: Props) => {
  return (
    <div className={containerCx} style={{ ...(rounded && { borderRadius: 28 }) }}>
      <Typography variant="body3">{children}</Typography>
    </div>
  );
};

export default Label;
