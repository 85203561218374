import { N200 } from '@/styles/color';
import { css } from '@emotion/css';

export const menuContainerCx = css`
  display: flex;
  flex-direction: column;
  padding: 24px 0 0;
`;

export const menuItemContainerCx = css`
  display: flex;
  column-gap: 8px;
  padding: 12px 16px 24px;
  width: 100%;
  overflow: scroll;
`;

export const buttonContainerCx = css`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 128px;
  padding: 12px;
  flex: 0 0 auto;
  border: 1px solid ${N200};
  border-radius: 12px;
`;

export const buttonCx = css`
  width: 24x;
  height: 24px;
  margin-bottom: 16px;
`;
