import type { ReactNode } from 'react';

import { containerCx } from './style';

interface Props {
  children: ReactNode;
  style?: any;
}

const Card = ({ children, style }: Props) => {
  return (
    <div className={containerCx} style={style}>
      {children}
    </div>
  );
};

export default Card;
