import { css } from '@emotion/css';

export const propertyContainerCx = css`
  padding: 24px 0 0;
  width: 100%;
`;

export const propertyTextContainerCx = css`
  margin: 0 16px 12px;
`;

export const propertyChipContainerCx = css`
  display: flex;
  column-gap: 4px;
  padding: 0 16px;
  margin-bottom: 12px;
  overflow: scroll;
`;

export const propertyListContainerCx = css`
  display: flex;
  column-gap: 8px;
  overflow: scroll;
  padding: 0 16px;
  margin-bottom: 24px;
`;

export const propertyDescriptionCx = css`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`;
