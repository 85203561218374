import Image from 'next/image';
import Typography from '@mui/material/Typography';
import Divider from '@/components/Divider';
import { useRouter } from 'next/router';
import { WidgetCardData } from '@/routes/Home/models/types';
import { useMobile } from '@/context/responsive';
import isArray from '@/utils/typecheck/isArray';
import Label from '@/components/Label';
import { Y600 } from '@/styles/color';
import { buttonContainerCx, buttonCx, imageCx, menuContainerCx, textContainerCx } from './style';

interface WidgetCardProps {
  data: WidgetCardData[];
  description: string;
  title: string;
  // widget?: string;
}

interface MenuButtonProps {
  image: string;
  title: string;
  text: string;
  type: string;
  author: string;
  profession: string;
  onClick: () => void;
}

const CARD_TYPE = {
  NEWS: 'news',
};

const MenuButton = ({ image, text, title, type, author, profession, onClick }: MenuButtonProps) => {
  const isNewsCard = type.toLowerCase() === CARD_TYPE.NEWS;

  return (
    <div className={buttonContainerCx} style={{ padding: isNewsCard ? 0 : 16 }} onClick={onClick}>
      <div className={buttonCx}>
        <Image className={imageCx} src={image} alt={text} width={56} height={56} unoptimized />
        {profession ? <Image src="/image/image-quotes.svg" alt="BestHome" width={60} height={42} unoptimized /> : null}
      </div>
      <div className={textContainerCx} style={{ padding: isNewsCard ? 16 : 0 }}>
        <Typography variant="body2" style={{ marginBottom: 12 }}>
          {title?.length ? title : text}
        </Typography>
        <div>
          <Typography variant="body2" fontWeight="bold" style={{ color: Y600 }}>
            {author}
          </Typography>
          <Typography variant="body3">{profession}</Typography>
          {isNewsCard ? (
            <div style={{ display: 'flex' }}>
              <Label rounded>{type}</Label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const WidgetCard = ({ data, description, title }: WidgetCardProps) => {
  const router = useRouter();
  const isMobile = useMobile();

  const cardData = data || [];

  const handleClick = (url: string) => router.push(url);

  return (
    <div style={{ paddingTop: 24, marginBottom: isMobile ? 0 : 24 }}>
      <div style={{ marginLeft: 16 }}>
        <Typography variant={isMobile ? 'h3' : 'h4'}>{title}</Typography>
        <Typography variant={isMobile ? 'body3' : 'body2'}>
          <span>{description}</span>
        </Typography>
        {/* <Label>{data.type}</Label> */}
      </div>

      <div className={menuContainerCx}>
        {isArray(cardData)
          ? cardData.map(card => {
              const comment = card.comment || '';
              const name = card.name || '';
              const profession = card.profession || '';
              const type = (card as any).widget || '';
              const isNewsCard = type.toLowerCase() === CARD_TYPE.NEWS;

              return (
                <MenuButton
                  key={`${name}-${profession}`}
                  image={isNewsCard ? card?.file?.url : card?.profile_photo_url || '/logo/120.png'}
                  title={card.title}
                  text={comment}
                  author={name}
                  profession={profession}
                  type={type}
                  onClick={() => handleClick(card.web_url)}
                />
              );
            })
          : null}
      </div>
      <Divider margin={16} />
    </div>
  );
};

export default WidgetCard;
