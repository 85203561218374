import Image from 'next/image';
import Typography from '@mui/material/Typography';
import Divider from '@/components/Divider';
import isArray from '@/utils/typecheck/isArray';
import { useRouter } from 'next/router';
import { useMobile } from '@/context/responsive';
import type { WidgetMenuData } from '@/routes/Home/models/types';
import { buttonContainerCx, buttonCx, menuContainerCx, menuItemContainerCx } from './style';

interface WidgetMenuProps {
  data: WidgetMenuData[];
  title: string;
}

interface MenuButtonProps {
  image: string;
  bg_image: string;
  text: string;
  onClick: () => void;
}

const MenuButton = ({ bg_image, image, text, onClick }: MenuButtonProps) => {
  const isMobile = useMobile();
  const isDesktop = !isMobile;

  const containerClass = isDesktop ? { width: 176, height: 148, padding: 24 } : {};

  return (
    <div
      className={buttonContainerCx}
      style={{
        ...containerClass,
        ...(bg_image
          ? {
              backgroundImage: `url(${bg_image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top',
              backgroundColor: 'rgba(0,0,0,0.5)',
              justifyContent: 'center',
              ...(isDesktop && { width: 276, height: 138 }),
            }
          : {}),
      }}
      onClick={onClick}
    >
      {bg_image ? (
        <Typography variant="h5" style={{ color: '#FFF', textAlign: 'center' }}>
          {text}
        </Typography>
      ) : (
        <>
          <div className={buttonCx}>
            {/* TODO: Change image when the icon is updated from BE side */}
            <Image src={image} alt={text} width={32} height={32} unoptimized />
            {/* <Image src="/logo/120.png" alt="BestHome" width={32} height={32} unoptimized /> */}
          </div>
          <Typography variant={isDesktop ? 'body2' : 'body3'}>{text}</Typography>
        </>
      )}
    </div>
  );
};

const WidgetMenu = ({ data, title }: WidgetMenuProps) => {
  const router = useRouter();
  const isMobile = useMobile();
  const isDesktop = !isMobile;

  const menuData = data || [];

  const handleClick = (url: string) => router.push(url);

  return isArray(menuData) ? (
    <>
      <div className={menuContainerCx}>
        <Typography variant={isMobile ? 'h3' : 'h4'} style={{ marginLeft: 16 }}>
          {title}
        </Typography>
        <div className={menuItemContainerCx} style={{ ...(isDesktop && { columnGap: 24 }) }}>
          {menuData.map(menu => (
            <MenuButton
              key={menu.name}
              image={menu.icon}
              bg_image={menu?.image_url || ''}
              text={menu.name}
              onClick={() => handleClick(menu.web_url)}
            />
          ))}
        </div>
      </div>
      <Divider margin={16} />
    </>
  ) : null;
};

export default WidgetMenu;
