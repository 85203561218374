import Chip from '@/components/Chip';
import Divider from '@/components/Divider';
import ProductCard from '@/components/ProductCard';
import { useMobile } from '@/context/responsive';
import { HomeCustomerData, WidgetCardData, WidgetFilter } from '@/routes/Home/models/types';
import { G500 } from '@/styles/color';
import isArray from '@/utils/typecheck/isArray';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import {
  propertyChipContainerCx,
  propertyContainerCx,
  propertyDescriptionCx,
  propertyListContainerCx,
  propertyTextContainerCx,
} from './style';

interface WidgetCarouselProps {
  title: string;
  description: string;
  data: HomeCustomerData[];
  filter?: WidgetFilter[];
}

const WidgetCarousel = ({ title, description, data, filter }: WidgetCarouselProps) => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const isMobile = useMobile();
  const isDesktop = !isMobile;

  const filterData = filter || [];
  const filterDataLength = filterData.length || 0;

  const widgetData = filterDataLength ? filterData : data || [];

  return (
    <div className={propertyContainerCx} style={{ ...(isDesktop && { marginBottom: 24 }) }}>
      <div className={propertyTextContainerCx} style={{ ...(isDesktop && { marginBottom: 24 }) }}>
        <Typography variant={isMobile ? 'h3' : 'h4'}>{title}</Typography>
        <Typography className={propertyDescriptionCx} variant={isMobile ? 'body3' : 'body2'}>
          <span>{description}</span>
          <b style={{ color: G500 }}>Lihat Semua</b>
        </Typography>
      </div>
      {filterDataLength ? (
        <div className={propertyChipContainerCx} style={{ ...(isDesktop && { marginBottom: 24 }) }}>
          {filterData.map(fil => {
            // TODO: di klik return card dalemny
            return (
              <Chip active={selectedFilter === fil.name} key={fil.name} onClick={() => setSelectedFilter(fil.name)}>
                {fil.name}
              </Chip>
            );
          })}
        </div>
      ) : null}
      <div className={propertyListContainerCx} style={{ ...(isDesktop && { columnGap: 24, marginBottom: 48 }) }}>
        {isArray(widgetData)
          ? widgetData.map((d, id: number) => {
              // TODO: Change key to proper one
              if (filterDataLength) {
                const cardWidgetData = d.card || [];
                return cardWidgetData.map(x => {
                  return <ProductCard key={id} data={x} />;
                });
              }
              return <ProductCard key={id} data={d as WidgetCardData} />;
            })
          : null}
      </div>
      <Divider margin={16} />
    </div>
  );
};

export default WidgetCarousel;
