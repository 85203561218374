import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useMobile } from '@/context/responsive';
import { N200 } from '@/styles/color';
import { aboutContainerCx, linksContainerCx, socialContainerCx, tabLinksContainerCx } from './style';

const SocialSection = () => {
  const isMobile = useMobile();

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabList = [
    {
      title: 'Properti Dijual di Indonesia',
      links: [
        { title: 'Properti Dijual di Aceh', url: '/' },
        { title: 'Properti Dijual di Bali', url: '/' },
        { title: 'Properti Dijual di Banten', url: '/' },
        { title: 'Properti Dijual di DIY', url: '/' },
        { title: 'Properti Dijual di DKI Jakarta', url: '/' },
        { title: 'Properti Dijual di Gorontalo', url: '/' },
        { title: 'Properti Dijual di Jambi', url: '/' },
      ],
    },
    {
      title: 'Properti Disewa di Indonesia',
      links: [
        { title: 'Properti Disewa di Aceh', url: '/' },
        { title: 'Properti Disewa di Bali', url: '/' },
        { title: 'Properti Disewa di Banten', url: '/' },
        { title: 'Properti Disewa di DIY', url: '/' },
        { title: 'Properti Disewa di DKI Jakarta', url: '/' },
        { title: 'Properti Disewa di Gorontalo', url: '/' },
        { title: 'Properti Disewa di Jambi', url: '/' },
      ],
    },
  ];

  const links = [
    { title: 'advertise', url: '/' },
    { title: 'contact us', url: '/' },
    { title: 'agent admin', url: '/' },
    { title: 'media sales', url: '/' },
    { title: 'legal', url: '/' },
    { title: 'privacy', url: '/' },
    { title: 'site map', url: '/' },
    { title: 'careers', url: '/' },
  ];

  const socialLinks = [
    { image: '/logo/social/logo-facebook.svg', title: 'facebook' },
    { image: '/logo/social/logo-tiktok.svg', title: 'tiktok' },
    { image: '/logo/social/logo-instagram.svg', title: 'instagram' },
    { image: '/logo/social/logo-twitter.svg', title: 'twitter' },
    { image: '/logo/social/logo-youtube.svg', title: 'youtube' },
  ];

  return (
    <>
      <div style={{ borderBottom: `1px solid ${N200}` }}>
        <div style={{ maxWidth: 1176, margin: 'auto' }}>
          <Tabs value={value} onChange={handleChange} aria-label="property-tabs">
            {tabList.map(tab => {
              return <Tab key={tab.title} label={tab.title} />;
            })}
          </Tabs>
          {tabList.map((tab, index) => {
            return (
              <div
                key={tab.title}
                role="tabpanel"
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                className={tabLinksContainerCx}
                style={{
                  ...(!isMobile
                    ? {
                        paddingBottom: 80,
                        marginBottom: 16,
                        display: value !== index ? 'none' : 'initial',
                      }
                    : {}),
                }}
              >
                <ul style={{ ...(!isMobile && { flexFlow: 'column wrap', height: 160 }) }}>
                  {tab.links.map(link => (
                    <li key={link.title} style={{ ...(!isMobile ? { width: 276 } : {}) }}>
                      <Typography variant="body2" style={{ marginBottom: 16 }}>
                        {link.title}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className={linksContainerCx} style={{ ...(!isMobile ? { paddingTop: 28, paddingBottom: 28 } : {}) }}>
        <ul style={{ ...(!isMobile ? { flexWrap: 'nowrap', justifyContent: 'center', columnGap: 40 } : {}) }}>
          {links.map(link => (
            <li key={link.title} style={{ ...(!isMobile ? { flexBasis: 'auto' } : {}) }}>
              <Typography variant="body2">{link.title}</Typography>
            </li>
          ))}
        </ul>
      </div>
      <div className={aboutContainerCx} style={{ ...(!isMobile ? { padding: 48, textAlign: 'center' } : {}) }}>
        <div style={{ maxWidth: 1176, margin: 'auto' }}>
          <Typography variant="body2" style={{ marginBottom: !isMobile ? 48 : 16 }}>
            BestHome Group is committed to ensuring digital accessibility for individuals with disabilities. We are
            continuously working to improve the accessibility of our web experience for everyone, and we welcome
            feedback and accommodation requests. If you wish to report an issue or seek an accommodation, please let us
            know.
          </Typography>
          <Typography variant="body2">
            BestHome, Inc. holds real estate brokerage licenses in multiple states. BestHome (Indonesia), Inc. holds
            real estate brokerage licenses in multiple provinces. A list of our real estate licenses is available here.
            § 442-H New York Standard Operating Procedures TREC: Information about brokerage services, Consumer
            protection notice California DRE #1522444
          </Typography>
        </div>
      </div>
      <div
        className={socialContainerCx}
        style={{ ...(!isMobile ? { paddingTop: 28, paddingBottom: 28, maxWidth: 1176, margin: 'auto' } : {}) }}
      >
        <Typography variant="body3">&copy;{new Date().getFullYear()} BestHome, Inc.</Typography>
        <ul>
          {socialLinks.map(link => (
            <li key={link.title}>
              <Image alt={link.title} src={link.image} width={24} height={24} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SocialSection;
