import { useQuery } from 'react-query';
import { useMemo } from 'react';

import fetcher from '@/utils/react-query-utilities/fetcher';
import { ENDPOINTS } from '@/constants/endpoints';

// import type { BaseResponse } from '@/model/types';
// import type { IsAuthenticateResponse } from '@/model/is-authenticate/types';
// import type { AuthenticationState } from '@/context/authentication/model/types';
// import type { APIError } from '@/utils/react-query-utilities/error';

// import normalize from './normalize';

/**
 * @function useGetHome
 */
const useGetHome = () => {
  const { status, data } = useQuery({
    queryKey: [ENDPOINTS.GET_HOME],
    queryFn: fetcher,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['status', 'data'],
  });

  return useMemo(() => {
    return {
      loading: status === 'loading',
      data: data?.data,
    };
    // const isAuth = normalize(status, data);
    // return {
    //   loading: status === 'loading',
    //   isAuth: isAuth,
    // };
  }, [data, status]);
};

export default useGetHome;
