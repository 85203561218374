import { N200 } from '@/styles/color';
import { css } from '@emotion/css';

export const tabLinksContainerCx = css`
  padding: 16px;

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    & li {
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
`;

export const linksContainerCx = css`
  padding: 16px;
  border-bottom: 1px solid ${N200};

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    & li {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      text-transform: capitalize;
    }
  }
`;

export const aboutContainerCx = css`
  padding: 16px;
  border-bottom: 1px solid ${N200};
`;

export const socialContainerCx = css`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  justify-content: space-between;

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 12px;

    & li {
      display: flex;
      align-items: center;
    }
  }
`;
