import type { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import { G500, N0, T50 } from '@/styles/color';
import { containerCx } from './styles';

interface Props {
  active?: boolean;
  children: ReactNode;
  color?: string;
  slim?: boolean;
  onClick?: () => void;
}

const Chip = ({ active, children, color, slim = false, onClick }: Props) => {
  return (
    <div
      className={containerCx}
      style={{
        color: color ? N0 : 'inherit',
        ...(active && { backgroundColor: T50, borderColor: G500, color: G500 }),
        backgroundColor: color,
        ...(slim && { padding: '2px 8px' }),
      }}
      onClick={onClick}
    >
      <Typography variant="body3">{children}</Typography>
    </div>
  );
};

export default Chip;
