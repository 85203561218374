import { N200 } from '@/styles/color';
import { css } from '@emotion/css';

export const menuContainerCx = css`
  display: flex;
  column-gap: 8px;
  padding: 24px 16px;
  width: 100%;
  overflow: scroll;
`;

export const buttonContainerCx = css`
  display: flex;
  flex-flow: column;
  width: 244px;
  padding: 16px;
  flex: 0 0 auto;
  border: 1px solid ${N200};
  border-radius: 12px;
`;

export const buttonCx = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const imageCx = css`
  border-radius: 50%;
`;

export const textContainerCx = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
