import { useMobile } from '@/context/responsive';
import type { WidgetCardData, WidgetCardFile } from '@/routes/Home/models/types';
import sanitize from '@/utils/json/sanitize';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import Chip from '../Chip';
import Label from '../Label';
import {
  productBodyCx,
  productCardContainerCx,
  productCardHeaderCx,
  productFooterCx,
  productFooterItemCx,
  productImageContainerCx,
  productTagContainerCx,
  productWishlistButtonCx,
} from './styles';

interface ProductCardProps {
  data: WidgetCardData;
}

const ProductCard = ({ data }: ProductCardProps) => {
  const isMobile = useMobile();

  const price = data.title || 0;
  const title = data.subtitle || '';
  const location = data.location || '';
  const chip = data.chip || '';
  const chipColor = data.chip_color || '#E64759';
  const images: WidgetCardFile = data.file || { url: '/logo/120.png', text: 'BestHome' };

  const tags = data.tag || [];
  const footerData = data.footer || [];

  return (
    <div className={productCardContainerCx} style={{ ...(!isMobile && { width: 276 }) }}>
      <div className={productCardHeaderCx}>
        {chip.length ? (
          <Chip color={chipColor} slim>
            {chip}
          </Chip>
        ) : (
          <div />
        )}
        <button type="button" className={productWishlistButtonCx}>
          <Image src="/icon/love.svg" width={24} height={24} />
        </button>
      </div>
      <div className={productImageContainerCx}>
        <Image
          src={images.url}
          layout="fill"
          alt={images.text}
          unoptimized
          style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
        />
      </div>
      <div className={productBodyCx}>
        <Typography variant="body1" fontWeight="bold">
          {price}
        </Typography>
        <Typography variant="body3">{title}</Typography>
        <Typography variant="body3">{location}</Typography>
        <div className={productTagContainerCx}>
          {tags.map(tag => (
            <Label key={tag}>{tag}</Label>
          ))}
        </div>
      </div>
      {footerData.length ? (
        <div className={productFooterCx}>
          {footerData.map(f => {
            // TODO: icon wait for BE return
            return (
              <div key={f.icon} className={productFooterItemCx}>
                <Image
                  src={f.icon.includes('http') ? f.icon : '/icon/house.svg'}
                  alt={f.icon.toString()}
                  layout="fixed"
                  width={16}
                  height={16}
                />
                <Typography variant="body3" dangerouslySetInnerHTML={{ __html: sanitize(f.value) }} />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ProductCard;
